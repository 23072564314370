'use client';

import Image from 'next/image';
import Link from 'next/link';

import 'swiper/css';
import 'swiper/css/pagination';

import clsx from 'clsx';
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ButtonFPot } from '@components';
import { CarouselButtonArrows } from '@components/shared/carousel/CarouselButtonArrows';
import { IHomePageSliders } from '@types';

import classes from './MainTop.module.scss';

const MainTop = ({ sliders, isMobile }: { sliders: IHomePageSliders[]; isMobile?: boolean }) => (
  <article className={classes.wrapper}>
    {sliders && sliders.length ? (
      <Swiper
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        speed={500}
        modules={[Navigation, Pagination, A11y, Scrollbar, Autoplay]}
        className={classes.swiperWrapper}
        breakpoints={{
          240: {
            pagination: {
              el: '.pagination',
              clickable: true
            }
          },
          960: {
            pagination: false
          }
        }}
      >
        {sliders
          .sort((i, j) => i.order - j.order)
          .map((item, index) => {
            const imageLink = isMobile ? item.mobileImage : item.image;
            return (
              <SwiperSlide key={item.id}>
                <div className={classes.slideContainer} key={item.id}>
                  <section
                    className={classes.root}
                    style={item.leftBackgroundColor ? { backgroundColor: item.leftBackgroundColor } : {}}
                  >
                    <div
                      className={classes.content}
                      style={item.leftBackgroundColor ? { backgroundColor: item.leftBackgroundColor } : {}}
                    >
                      <div className={classes.container}>
                        {index === 0 ? (
                          <h1 className={classes.title}>{item.header || ''}</h1>
                        ) : (
                          <p className={classes.title}>{item.header || ''}</p>
                        )}

                        <div className={classes.subTitle}>
                          {item.description ? <div className={classes.description}>{item.description}</div> : ''}
                        </div>
                        <Link href={`${item.url}` || '/catalog'}>
                          <ButtonFPot
                            label={item.buttonLabel || 'Обрати комплект'}
                            classNameWrapper={classes.buttonLabel}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className={classes.gridItem}>
                      <Link href={`${item.url}` || '/catalog'}>
                        <figure>
                          <Image
                            className={classes.image}
                            src={imageLink}
                            alt={item.header}
                            quality={100}
                            priority={index === 0}
                            fill
                          />
                        </figure>
                      </Link>
                    </div>
                  </section>
                </div>
              </SwiperSlide>
            );
          })}
        <CarouselButtonArrows classNameWrapper={classes.buttonArrow} prevArrow={classes.prevArrowButton} />
        <div className={clsx('pagination', classes.customPagination)}></div>
      </Swiper>
    ) : null}
  </article>
);

export default MainTop;
